.adm-water-mark {
  --z-index: var(--adm-water-mark-z-index, 2000);
  position: absolute;
  z-index: var(--z-index);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-repeat: repeat;
}
.adm-water-mark-full-page {
  position: fixed;
}
